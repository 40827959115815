export const useTrustStore = defineStore('trust', () => {
    const companyList = ref([
        {
            name: 'Apple',
            logo: 'https://logo.clearbit.com/apple.com',
            trusted: false,
            rating: 10,
            url: 'https://apple.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: false,
                acceptPhysicalMail: false,
                acceptEmailNewsletter: false,
                acceptPushNotifications: true,
                acceptTvAddressable: false,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: false,
            },
        },
        {
            name: 'Google',
            logo: 'https://logo.clearbit.com/google.com',
            trusted: true,
            rating: 60,
            url: 'https://google.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Amazon',
            logo: 'https://logo.clearbit.com/amazon.com',
            trusted: true,
            rating: 55,
            url: 'https://amazon.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Facebook',
            logo: 'https://logo.clearbit.com/facebook.com',
            trusted: false,
            rating: 45,
            url: 'https://facebook.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Netflix',
            logo: 'https://logo.clearbit.com/netflix.com',
            trusted: true,
            rating: 30,
            url: 'https://netflix.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Tesla',
            logo: 'https://logo.clearbit.com/tesla.com',
            trusted: true,
            rating: 80,
            url: 'https://tesla.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Twitter',
            logo: 'https://logo.clearbit.com/twitter.com',
            trusted: false,
            rating: '45',
            url: 'https://twitter.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Shopify',
            logo: 'https://logo.clearbit.com/shopify.com',
            trusted: true,
            rating: 70,
            url: 'https://shopify.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Slack',
            logo: 'https://logo.clearbit.com/slack.com',
            trusted: true,
            rating: 40,
            url: 'https://slack.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Zoom',
            logo: 'https://logo.clearbit.com/zoom.com',
            trusted: true,
            rating: 50,
            url: 'https://zoom.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Spotify',
            logo: 'https://logo.clearbit.com/spotify.com',
            trusted: false,
            rating: 60,
            url: 'https://spotify.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Uber',
            logo: 'https://logo.clearbit.com/uber.com',
            trusted: false,
            rating: 25,
            url: 'https://uber.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Lyft',
            logo: 'https://logo.clearbit.com/lyft.com',
            trusted: false,
            rating: 20,
            url: 'https://lyft.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Airbnb',
            logo: 'https://logo.clearbit.com/airbnb.com',
            trusted: true,
            rating: 70,
            url: 'https://airbnb.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Microsoft',
            logo: 'https://logo.clearbit.com/microsoft.com',
            trusted: true,
            rating: 12,
            url: 'https://microsoft.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: false,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: false,
                acceptPushNotifications: true,
                acceptTvAddressable: false,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: false,
            },
        },
        {
            name: 'Pinterest',
            logo: 'https://logo.clearbit.com/pinterest.com',
            trusted: true,
            rating: 30,
            url: 'https://pinterest.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Reddit',
            logo: 'https://logo.clearbit.com/reddit.com',
            trusted: true,
            rating: 40,
            url: 'https://reddit.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Snapchat',
            logo: 'https://logo.clearbit.com/snapchat.com',
            trusted: false,
            rating: 15,
            url: 'https://snapchat.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Dropbox',
            logo: 'https://logo.clearbit.com/dropbox.com',
            trusted: true,
            rating: 60,
            url: 'https://dropbox.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Stripe',
            logo: 'https://logo.clearbit.com/stripe.com',
            trusted: true,
            rating: 80,
            url: 'https://stripe.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Twitch',
            logo: 'https://logo.clearbit.com/twitch.com',
            trusted: true,
            rating: 50,
            url: 'https://twitch.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'LinkedIn',
            logo: 'https://logo.clearbit.com/linkedin.com',
            trusted: true,
            rating: 60,
            url: 'https://linkedin.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'PayPal',
            logo: 'https://logo.clearbit.com/paypal.com',
            trusted: true,
            rating: 70,
            url: 'https://paypal.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Square',
            logo: 'https://logo.clearbit.com/square.com',
            trusted: true,
            rating: 60,
            url: 'https://square.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'DoorDash',
            logo: 'https://logo.clearbit.com/doordash.com',
            trusted: false,
            rating: 25,
            url: 'https://doordash.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Robinhood',
            logo: 'https://logo.clearbit.com/robinhood.com',
            trusted: false,
            rating: 35,
            url: 'https://robinhood.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Coinbase',
            logo: 'https://logo.clearbit.com/coinbase.com',
            trusted: true,
            rating: 80,
            url: 'https://coinbase.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Slack',
            logo: 'https://logo.clearbit.com/slack.com',
            trusted: true,
            rating: 40,
            url: 'https://slack.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Lyft',
            logo: 'https://logo.clearbit.com/lyft.com',
            trusted: false,
            rating: 20,
            url: 'https://lyft.com',
            user_preferences: {
                isTrusted: false,
                dontResell: false,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
        {
            name: 'Airbnb',
            logo: 'https://logo.clearbit.com/airbnb.com',
            trusted: true,
            rating: 70,
            url: 'https://airbnb.com',
            user_preferences: {
                isTrusted: true,
                dontResell: true,
                digitalAutomated: true,
                acceptAds: true,
                acceptPhysicalMail: true,
                acceptEmailNewsletter: true,
                acceptPushNotifications: true,
                acceptTvAddressable: true,
                acceptPhoneSms: true,
                acceptPhoneTelemarketing: true,
            },
        },
    ]);

    function getCompany(company_name: string) {
        return companyList.value.filter((company) => company.name === company_name)[0];
    }

    return { companyList, getCompany };
});
