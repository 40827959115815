<template>
    <div class="sidebar select-none">
        <div class="p-6">
            <prime-select v-model="selectedCategory" :options="categories" outlined />
        </div>

        <div v-if="selectedCategory == 'companies'" class="sidebar-items-container">
            <sidebar-company-item
                v-for="company in trustedCompanies"
                :key="company.name"
                :company="company"
                v-if="isShowingTrusted"
            />

            <sidebar-company-item v-for="(company, i) in trust_store.companyList" :key="i" :company="company" v-else />

            <div class="trust-toggle-container">
                <div class="trust-toggle">
                    <div class="trusted-btn" @click="isShowingTrusted = true" :class="{ 'active-toggle': isShowingTrusted }">
                        Trusted

                        <span class="count-badge-style">{{ trustedCompaniesCount }}</span>
                    </div>
                    <div class="all-btn" @click="isShowingTrusted = false" :class="{ 'active-toggle': !isShowingTrusted }">
                        Untrusted <span class="count-badge-style">{{ allCompaniesCount }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedCategory == 'contacts'" class="sidebar-items-container">
            <sidebar-contact-item v-for="contact in buzz_store.contacts" :key="contact.name" :contact="contact" />

            <div class="trust-toggle-container">
                <div class="trust-toggle">
                    <div class="trusted-btn" @click="isShowingTrusted = true" :class="{ 'active-toggle': isShowingTrusted }">
                        Trusted

                        <span class="count-badge-style">{{ trustedCompaniesCount }}</span>
                    </div>
                    <div class="all-btn" @click="isShowingTrusted = false" :class="{ 'active-toggle': !isShowingTrusted }">
                        Untrusted <span class="count-badge-style">{{ allCompaniesCount }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SidebarCompanyItem from '~/components/trust/SidebarCompanyItem.vue';
import SidebarContactItem from '~/components/trust/SidebarContactItem.vue';
import PrimeSelect from 'primevue/select';

const categories = ['companies', 'contacts'];

const route = useRoute();

const initialCategory = () => {
    if (route.query.selected) return route.query.selected;

    return route.name === 'Contacts' ? 'contacts' : 'companies';
};

const selectedCategory = ref(initialCategory());

const trust_store = useTrustStore();
const buzz_store = useBuzzStore();

const isShowingTrusted = ref(false);

//computed property that counrs which companies isTrusted is true
const trustedCompaniesCount = computed(() => trust_store.companyList.filter((company) => company.trusted).length);
const allCompaniesCount = computed(() => trust_store.companyList.length);

//trusted companies list
const trustedCompanies = computed(() => trust_store.companyList.filter((company) => company.trusted));
</script>

<style lang="scss" scoped>
.sidebar {
    @apply border-r  border-slate-300 fixed bg-white-700 bottom-0 z-20 w-full;

    //width minus 280px of the sidebar

    height: calc(100vh - var(--size-header-height));

    @apply lg:w-[calc(100%-280px)];
    @apply xl:w-[280px];
}

.sidebar-items-container {
    @apply overflow-y-scroll;

    //sidebar height is equal to 100vh - header height - 40px of the select commponent - 24px of vertical padding (meaning 48px)
    height: calc(100vh - var(--size-header-height) - (24px * 2) - 40px);

    //it has the size of the select (plus its padding) to cancel the actual select component
    padding-bottom: calc(24px * 2 + 40px);

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: theme('colors.slate-400');
        border-radius: 5px;
    }
}

.trust-toggle-container {
    @apply p-6;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.trust-toggle {
    background: rgb(241, 241, 241);
    @apply flex justify-between rounded-full p-[2px];
}

.trusted-btn,
.all-btn {
    @apply p-2 rounded-full cursor-pointer text-center w-1/2 flex justify-center items-center gap-2;
}

.trusted-btn {
    color: var(--slate-500, rgba(2, 6, 23, 0.5));
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.all-btn {
    color: var(--slate-500, rgba(2, 6, 23, 0.5));
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.active-toggle {
    background: white;
    color: var(--slate-700, #020617);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    .count-badge-style {
        @apply bg-blue-700;
    }
}

.count-badge-style {
    color: var(--white-700, #f8fafc);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @apply bg-slate-500 px-2  rounded-full flex justify-center items-center;
    width: 24px !important;
    height: 24px !important;
}
</style>
