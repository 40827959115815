<template>
    <div class="contact-item-container" @click="router.push(`/contact/${contact.user_id}`)">
        <div class="contact-item-picture">
            <img :src="contact.profile_picture" class="w-5 h-5" />
            <buzz-lightning-icon class="absolute -right-1 -top-1" />
        </div>

        <div class="flex flex-col flex-grow">
            <span class="text-style-link">{{ contact.name }}</span>
            <label class="message-preview-text">{{ contact.messages[1].content }}</label>
        </div>
    </div>
</template>

<script setup>
import BuzzLightningIcon from '~/assets/icons/buzz-lightning.svg';

const props = defineProps({
    contact: Object,
});

const router = useRouter();
</script>

<style lang="scss" scoped>
.contact-item-container {
    @apply p-3 h-16 rounded flex justify-center items-center gap-3 hover:bg-blue-200 cursor-pointer mx-6;
}

.contact-item-picture {
    @apply bg-slate-100 w-9 h-9 rounded-full relative flex justify-center items-center bg-[white];

    img {
        @apply w-9 h-9 rounded-full border border-green-700;

        //image should not be stretched or shrinked
        object-fit: cover;
    }
}

.message-preview-text {
    @apply text-slate-500 cursor-pointer;
    width: 120px; /* Set a width for the element */
    white-space: nowrap; /* Prevent text from wrapping to a new line */
    overflow: hidden; /* Hide any text that overflows beyond the element's width */
    text-overflow: ellipsis; /* Display an ellipsis (...) to represent text that overflows */
}
</style>
