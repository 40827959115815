export const useBuzzStore = defineStore('buzz', () => {
    const contacts = ref([
        {
            user_id: 1,
            name: 'Liza Soberano',
            email: 'lizasoberano@gmail.com',
            phone_number: '09123456789',
            profile_picture: 'https://randomuser.me/api/portraits/women/7.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'Hello, how are you?',
                    timestamp: '2024-07-23T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I am good, thank you.',
                    timestamp: '2024-07-23T10:01:00',
                },
                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'That is good to hear!',
                    timestamp: '2024-07-23T10:02:00',
                },

                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'Did you know that the sky is blue?',
                    timestamp: '2024-07-23T10:03:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Yes',
                    timestamp: '2024-07-23T10:04:00',
                },
                {
                    type: 'image',
                    sender: 'Liza Soberano',
                    image_url: 'https://cdn.pixabay.com/photo/2022/03/06/05/30/clouds-7050884_640.jpg',
                    timestamp: '2024-07-23T10:05:00',
                },

                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'See?',
                    timestamp: '2024-07-23T10:06:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Yes',
                    timestamp: '2024-07-23T10:07:00',
                },

                //she says: sorry for sending too many messages
                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'Sorry for sending too many messages.',
                    timestamp: '2024-07-23T10:08:00',
                },

                //its just that I want this to be a long conversation so that I can test the scrolling
                {
                    type: 'text',
                    sender: 'Liza Soberano',
                    content: 'It is just that I want this to be a long conversation so that I can test the scrolling.',
                    timestamp: '2024-07-23T10:09:00',
                },
            ],
        },
        {
            user_id: 2,
            name: 'Kathryn Bernardo',
            email: 'kathrin@gmail.comm',
            phone_number: '09123456789',
            profile_picture: 'https://randomuser.me/api/portraits/women/2.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Kathryn Bernardo',
                    content: 'Hello, did you buy milk?',
                    timestamp: '2024-07-23T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'No, I am still at work.',
                    timestamp: '2024-07-23T10:01:00',
                },
                {
                    type: 'text',
                    sender: 'Kathryn Bernardo',
                    content: 'Alright, I will buy it.',
                    timestamp: '2024-07-23T10:02:00',
                },
            ],
        },

        {
            user_id: 3,
            name: 'Jane Doe',
            email: 'jane@gmail.com',
            phone_number: '09123456789',
            profile_picture: 'https://randomuser.me/api/portraits/women/9.jpg',
            messages: [
                //jane says hello, how are you, you say i'm good, she says that's great

                {
                    type: 'text',
                    sender: 'Jane Doe',
                    content: 'Hello, how are you?',
                    timestamp: '2024-07-23T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I am good, thank you.',
                    timestamp: '2024-07-23T10:01:00',
                },
                {
                    type: 'text',
                    sender: 'Jane Doe',
                    content: 'That is great to hear!',
                    timestamp: '2024-07-23T10:02:00',
                },
            ],
        },

        //Lila mare
        {
            user_id: 4,
            name: 'Lila Mare',
            email: 'lizamare@gmail.com',
            phone_number: '09123456789',
            profile_picture: 'https://randomuser.me/api/portraits/women/3.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Lila Mare',
                    content: 'I am going to the beach.',
                    timestamp: '2024-07-23T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'That is great!',
                    timestamp: '2024-07-23T10:01:00',
                },
                {
                    type: 'text',
                    sender: 'Lila Mare',
                    content: 'Do you want to come?',
                    timestamp: '2024-07-23T10:02:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Yes',
                    timestamp: '2024-07-23T10:03:00',
                },
            ],
        },

        //another contact
        {
            user_id: 5,
            name: 'Michael Jordan',
            email: 'emaim@michaeljordan.com',
            phone_number: '09123456789',
            profile_picture: 'https://randomuser.me/api/portraits/men/36.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Michael Jordan',
                    content: 'Hello, how are you?',
                    timestamp: '2024-07-23T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I am good, thank you.',
                    timestamp: '2024-07-23T10:01:00',
                },
                {
                    type: 'text',
                    sender: 'Michael Jordan',
                    content: 'That is good to hear!',
                    timestamp: '2024-07-23T10:02:00',
                },
            ],
        },

        {
            user_id: 6,
            name: 'John Doe', // Replace with a random name generator if needed
            email: 'email@randomman.com',
            phone_number: '09876543210',
            profile_picture: 'https://randomuser.me/api/portraits/men/66.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'John Doe', // Match the random name here
                    content: 'Hey, what’s up?',
                    timestamp: '2024-09-10T09:30:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Not much, just working.',
                    timestamp: '2024-09-10T09:31:00',
                },
                {
                    type: 'text',
                    sender: 'John Doe',
                    content: 'Nice! Keep it up.',
                    timestamp: '2024-09-10T09:32:00',
                },
            ],
        },

        {
            user_id: 7,
            name: 'James Smith',
            email: 'email@jamessmith.com',
            phone_number: '09112233445',
            profile_picture: 'https://randomuser.me/api/portraits/men/65.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'James Smith',
                    content: 'Hey, are you free for lunch today?',
                    timestamp: '2024-09-12T12:15:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Yes, I am free.',
                    timestamp: '2024-09-12T12:16:00',
                },
                {
                    type: 'text',
                    sender: 'James Smith',
                    content: 'Great! See you at 1 PM.',
                    timestamp: '2024-09-12T12:17:00',
                },
            ],
        },

        // Second man
        {
            user_id: 8,
            name: 'David Johnson',
            email: 'email@davidjohnson.com',
            phone_number: '09334455667',
            profile_picture: 'https://randomuser.me/api/portraits/men/99.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'David Johnson',
                    content: 'How was your weekend?',
                    timestamp: '2024-09-11T08:45:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'It was relaxing, thanks. How about you?',
                    timestamp: '2024-09-11T08:46:00',
                },
                {
                    type: 'text',
                    sender: 'David Johnson',
                    content: 'Pretty good, I went hiking.',
                    timestamp: '2024-09-11T08:47:00',
                },
            ],
        },

        // Woman
        {
            user_id: 9,
            name: 'Emma Williams',
            email: 'email@emmawilliams.com',
            phone_number: '09445566778',
            profile_picture: 'https://randomuser.me/api/portraits/women/45.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Emma Williams',
                    content: 'Hey! Long time no see.',
                    timestamp: '2024-09-10T14:30:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I know! How have you been?',
                    timestamp: '2024-09-10T14:31:00',
                },
                {
                    type: 'text',
                    sender: 'Emma Williams',
                    content: 'I’ve been good, just busy with work.',
                    timestamp: '2024-09-10T14:32:00',
                },
            ],
        },

        {
            user_id: 10,
            name: 'William Brown',
            email: 'email@williambrown.com',
            phone_number: '09556677889',
            profile_picture: 'https://randomuser.me/api/portraits/men/22.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'William Brown',
                    content: 'Hey, have you heard about the new project they’re starting at work? Sounds exciting!',
                    timestamp: '2024-09-13T09:15:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content:
                        'Yeah, I did! I’m actually quite interested in being a part of it. Do you think it’s going to be a lot of work?',
                    timestamp: '2024-09-13T09:17:00',
                },
                {
                    type: 'text',
                    sender: 'William Brown',
                    content: 'I think it will be, but it’ll be worth it. The opportunities for growth seem pretty huge.',
                    timestamp: '2024-09-13T09:20:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Totally agree. I might talk to the manager about getting involved!',
                    timestamp: '2024-09-13T09:22:00',
                },
            ],
        },

        // Second contact (woman)
        {
            user_id: 11,
            name: 'Sophia Thompson',
            email: 'email@sophiathompson.com',
            phone_number: '09667788990',
            profile_picture: 'https://randomuser.me/api/portraits/women/68.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Sophia Thompson',
                    content: 'Hey, do you want to catch up this weekend? It’s been ages since we last hung out!',
                    timestamp: '2024-09-14T15:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I’d love to! What do you have in mind?',
                    timestamp: '2024-09-14T15:05:00',
                },
                {
                    type: 'text',
                    sender: 'Sophia Thompson',
                    content: 'Maybe a coffee and walk in the park? We can chat and unwind. I know a great place downtown.',
                    timestamp: '2024-09-14T15:10:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'That sounds perfect! I’m free on Saturday afternoon. Does that work for you?',
                    timestamp: '2024-09-14T15:12:00',
                },
                {
                    type: 'text',
                    sender: 'Sophia Thompson',
                    content: 'Saturday works great! Let’s meet at 2 PM.',
                    timestamp: '2024-09-14T15:14:00',
                },
            ],
        },

        // Third contact (man)
        {
            user_id: 12,
            name: 'Ethan Martinez',
            email: 'email@ethanmartinez.com',
            phone_number: '09778899001',
            profile_picture: 'https://randomuser.me/api/portraits/men/53.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Ethan Martinez',
                    content: 'Hey, I’ve been meaning to ask, are you still planning that trip next month?',
                    timestamp: '2024-09-12T18:45:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Yeah, definitely! I’m just finalizing a few things. Are you still thinking about joining?',
                    timestamp: '2024-09-12T18:47:00',
                },
                {
                    type: 'text',
                    sender: 'Ethan Martinez',
                    content:
                        'I’d love to, but I need to check with work first. Things have been really busy lately, but I really need a break!',
                    timestamp: '2024-09-12T18:50:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I hear you. Hopefully, you can make it. It’d be fun to travel together again.',
                    timestamp: '2024-09-12T18:52:00',
                },
                {
                    type: 'text',
                    sender: 'Ethan Martinez',
                    content: 'Fingers crossed! I’ll keep you posted.',
                    timestamp: '2024-09-12T18:54:00',
                },
            ],
        },

        // Fourth contact (woman)
        {
            user_id: 13,
            name: 'Olivia Davis',
            email: 'email@oliviadavis.com',
            phone_number: '09889900112',
            profile_picture: 'https://randomuser.me/api/portraits/women/72.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Olivia Davis',
                    content:
                        'Hi! Have you had the chance to check out that new restaurant downtown? I’ve heard great things about it.',
                    timestamp: '2024-09-11T19:30:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I haven’t been there yet, but I’ve been hearing a lot of buzz too. Should we go together sometime?',
                    timestamp: '2024-09-11T19:35:00',
                },
                {
                    type: 'text',
                    sender: 'Olivia Davis',
                    content: 'Absolutely! Let’s plan for next week. I’ll make a reservation.',
                    timestamp: '2024-09-11T19:40:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Sounds perfect. I’m free any evening after work.',
                    timestamp: '2024-09-11T19:42:00',
                },
                {
                    type: 'text',
                    sender: 'Olivia Davis',
                    content: 'Great! I’ll book it for Thursday at 7 PM.',
                    timestamp: '2024-09-11T19:45:00',
                },
            ],
        },

        // Fifth contact (man)
        {
            user_id: 14,
            name: 'Liam Wilson',
            email: 'email@liamwilson.com',
            phone_number: '09990011223',
            profile_picture: 'https://randomuser.me/api/portraits/men/18.jpg',
            messages: [
                {
                    type: 'text',
                    sender: 'Liam Wilson',
                    content:
                        'Hey, I’ve been working on that coding project we talked about. It’s coming along, but I’ve hit a few roadblocks.',
                    timestamp: '2024-09-10T10:00:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'Oh, no! What kind of problems are you facing?',
                    timestamp: '2024-09-10T10:05:00',
                },
                {
                    type: 'text',
                    sender: 'Liam Wilson',
                    content:
                        'It’s mainly a few bugs in the backend. I think I’ve narrowed it down to a database issue, but I can’t seem to fix it.',
                    timestamp: '2024-09-10T10:10:00',
                },
                {
                    type: 'text',
                    sender: 'You',
                    content: 'I can take a look if you want. Send me the code, and I’ll see if I can spot anything.',
                    timestamp: '2024-09-10T10:12:00',
                },
                {
                    type: 'text',
                    sender: 'Liam Wilson',
                    content: 'That would be amazing! I’ll send it over now.',
                    timestamp: '2024-09-10T10:14:00',
                },
            ],
        },
    ]);

    function getContactDetails(user_id: number) {
        //find the contact with the user_id
        for (let contact of contacts.value) {
            if (contact.user_id == user_id) {
                return contact;
            }
        }
    }

    function sendTextMessage(user_id: number, message: object) {
        //find the contact with the user_id
        for (let contact of contacts.value) {
            if (contact.user_id == user_id) {
                //add the message to the contact
                contact.messages.push(message);
            }
        }
    }

    return { contacts, getContactDetails, sendTextMessage };
});
