<template>
    <div class="company-item-container" @click="router.push(`/${company.name}`)">
        <div class="company-item-logo-container" :class="{ '!border-green-700 border-2': company.trusted }">
            <img :src="company.logo" class="w-5 h-5" />
            <trust-badge-icon class="absolute -right-2 -bottom-2 transform scale-[0.4]" v-if="company.trusted" />
        </div>

        <div class="flex flex-col flex-grow">
            <span class="text-style-link">{{ company.name }}</span>
            <label class="text-slate-500 cursor-pointer">{{ company.url.replace('https://', '') }}</label>
        </div>
    </div>
</template>

<script setup>
import TrustBadgeIcon from '~/assets/icons/trust-badge.svg';

const props = defineProps({
    company: Object,
});

const router = useRouter();
</script>

<style lang="scss" scoped>
.company-item-container {
    @apply p-3 h-16 rounded flex justify-center items-center gap-3 hover:bg-blue-200 cursor-pointer mx-6;
}

.company-item-logo-container {
    @apply bg-slate-100 w-9 h-9 rounded-full border relative flex border-slate-300 justify-center items-center bg-[white];
}
</style>
